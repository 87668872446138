import { FancyReactMarkdown } from "../../../../../components/index"

export const text =
    [

    //Oct 12
    <FancyReactMarkdown>{`
# October 12th, 2022 - Wednesday

Made a prototype of the Upcoming Events calendar (see above).

Next steps: Refactor.  Start populating it. 

`}</FancyReactMarkdown>,

]
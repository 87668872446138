import { FancyReactMarkdown, Score } from "../../../../../components/index"
import { WritingIds, WritingLinkCard } from "../../../../../components/WritingLinkCards"


export const text =
[
 <FancyReactMarkdown>{`# October 18th, 2022 - Monday
   
Fixed the weird styling on links and the rest of the refactoring items from Oct 14.  Small victories, yay!
`}</FancyReactMarkdown>
  ]

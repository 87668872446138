import ReactMarkdown from 'react-markdown';

export const text =
<ReactMarkdown>{`
# October 9th, 2022 - Sunday

Hello, World! 

I began rebuilding this website in September, and I finally decided to start blogging about my progress.
I'll try to use blogs posts to communicate what's new with the very, very large
undertaking.  

`}</ReactMarkdown>
import { FancyReactMarkdown } from "../../../../../components/index"

export const text =
[
<FancyReactMarkdown>{`
# March 17th, 2023 - Friday

It's been almost 5 months since I last blogged.  I decided (finally) to make a major update to the layout of my site.  I'd been planning it for a while, and now it's done.  The main blog appears on the homepage.  The old home page (with links to my subblogs) has been moved [here](/#/categories)

---

In other news, I've been trying to get better at chess.  Currently rated about 2100 on Lichess, but my most recent game felt like I was playing quite poorly (even though I won). 
`}</FancyReactMarkdown>,
  <iframe width={600} height={371} src="https://lichess.org/study/embed/0KlpAWYL/SJ5Aqtwt" frameborder={0}></iframe>,
<FancyReactMarkdown>{`
  It doesn't feel good to win by psyching out my opponent.  Things to remember:

  * My original piece sac 9. Nxb5 was a mistake.  It seemed clever at first, but I overlooked 9... Qd5.  Retreating the Bishop to e2 would have left me in a much better position.
  * The big lapse in my tactical vision, however, was 12. Qc2, which overlooks that the a pawn becomes pinned.  If my opponent had noticed, this would have destroyed me.
`}</FancyReactMarkdown>

]

